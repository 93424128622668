import React from "react";
import { IconButton } from 'material-ui';
import { Dialog } from 'material-ui';
import { FlatButton } from 'material-ui';
import ActionInfo from 'material-ui/svg-icons/action/info';
import mui_styles from './styles/mui_styles';
import IsotopHeaderBanner from "./IsotopHeaderBanner";

class IsotopHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open_info: false
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClick = () => {
    this.setState({open_info: true});
  }

  handleClose = () => {
    this.setState({open_info: false});
  }

  render() {
    const actions = [
      <FlatButton
        label='Close'
        primary={true}
        keyboardFocused={true}
        onClick={this.handleClose}
      />
    ];
    return(
      <div className="App-header">
        {/*<div className="App-header-top">
          <img alt="Periodic triangulation" className="App-header-deco" src={headerImage} title="Periodic triangulation"/>
        </div>*/}
        <div className="App-header-bottom">
          <div className="App-header-title">
            Isotop
          </div>
          <div className="App-header-baseline">
            {this.props.motto}
          </div>
          <IconButton onClick={this.handleClick} tooltip="More explanation" style={mui_styles.icon_motto} >
            <ActionInfo color="#F2F2F2"/>
          </IconButton>
        </div>
        <IsotopHeaderBanner 
          text={this.props.banner}
        />
        <Dialog
          title="Isotop : how does it work ?"
          actions={actions}
          modal={false}
          open={this.state.open_info}
          onRequestClose={this.handleClose}
          contentClassName="IsotopMoreExplanationsDialog">
          {this.props.info}
        </Dialog>
      </div>
    );
  }
}

export default IsotopHeader;
