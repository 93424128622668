import grit from 'grit-parser';
import Grammar from './res/grammar';

export default class IsotopPolyChecker {
  constructor() {
    this.parser = grit(Grammar);
  }

  static isEmpty(polynomial) {
    return (polynomial === undefined) || (polynomial.length === 0);
  }

  validate(polynomial) {
    try {
      this.parser.parse(polynomial);
      return true;
    } catch (err) {
      return false;
    }
  }
}
