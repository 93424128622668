const Axios = require('axios').default;

// const API_PROTO = process.env.REACT_APP_API_PROTOCOL;
// const API_SERVER = process.env.REACT_APP_API_SERVER;
// const API_PORT = process.env.REACT_APP_API_PORT;
const BASE_URL = process.env.REACT_APP_API_URL;
const API_ROOT = process.env.REACT_APP_API_ROOT;

const APIHeaders = { 'Content-Type': 'application/json' };
const APIRequest = Axios.create({
  baseURL: BASE_URL,
  // url: API_ROOT,
  headers: APIHeaders 
});

console.log(`API server => [ ${BASE_URL} ]`);
console.log(`API root => [ ${API_ROOT} ]`);

export { APIRequest, API_ROOT };
