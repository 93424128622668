import React from 'react';
import Paper from 'material-ui/Paper';
import CircularProgress from 'material-ui/CircularProgress';
import MuiStyle from './styles/mui_styles';


class IsotopProgress extends React.Component {
  render() {
    if (this.props.count > 0) {
      let statusboxes = this.props.statuses.map((status, index) => <div className="App-progress-status" key={index}>{status}</div>);
      return(
        <div className="App-progress">
          <Paper
            style={MuiStyle.progress}
            zDepth={5}
            circle={true}>
            <CircularProgress
              size={400}
              thickness={20}/>
              <div className="App-progress-statuses">
                {statusboxes}
              </div>
          </Paper>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default IsotopProgress;
