import React, {Component} from 'react';
import {Helmet} from 'react-helmet';
import WebFont from 'webfontloader';
import './styles/App.css';

import IsotopProgress from './IsotopProgress';
import IsotopFooter from './IsotopFooter';
import IsotopHeader from './IsotopHeader';
import IsotopCore from './IsotopCore.jsx';
import IsotopError from './IsotopError';

import loriaLogo from './images/logo-loria.svg';
import inriaLogo from './images/logo-inria.svg';
import ulLogo from './images/logo-ul.svg';
import cnrsLogo from './images/logo-cnrs.svg';
import blobs from './res/blobs.json';
// import { blob } from 'd3';

// import { threadId } from 'worker_threads';
// import info from '@material-ui/icons/info';


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      request_in_progress: false,
      error: "", 
      status: "",
      statuses: [],
      open_info: false
    };
    this.messages = new Map();
    this.operationsInProgress = 0;
    this.handleRequestStatus = this.handleRequestStatus.bind(this);
    this.handleError = this.handleError.bind(this);
    this.clearError = this.clearError.bind(this);
    blobs.loria.image = loriaLogo;
    blobs.inria.image = inriaLogo;
    blobs.ul.image = ulLogo;
    blobs.cnrs.image = cnrsLogo;
    this.infoText = (
      <div>
        <p>{blobs.explanation}</p>
        <p>
          Please refer to <a href="http://gamble.loria.fr/software.html">Gamble software page</a> for further informations. 
        </p>
      </div> );
    this.creditText = (
      <div>
        <p className="credit-line">
          - Core technology: <a href="http://gamble.loria.fr/software.html">Isotop Software</a> by Gamble Inria Team-project
        </p>
        <hr/>
        <p className="credit-line">
          - Graph library: <a href="https://mauriciopoppe.github.io/function-plot/">Function Plot </a> by <a href="https://twitter.com/mauricio_poppe">Mauricio Poppe</a>
        </p>
      </div>
    );
    
  }

  handleRequestStatus = (in_progress, op, msg = '') => {
    if (in_progress) {
      this.operationsInProgress += 1;
      this.messages.set(op, msg);
    } else {
      if (this.operationsInProgress > 0) {
        this.operationsInProgress -= 1;
      }
      this.messages.delete(op);
    }
    this.setState({
      ...this.state.values, 
      request_in_progress: (this.operationsInProgress > 0), 
      status: msg, 
      statuses: [...this.messages.values()] }
    );
  }

  handleError = (error) => {
    let err_msg = "";
    if ((error.response !== undefined) && (error.response.hasOwnProperty('data'))) {
      err_msg = error.response.data.error+" (HTTP "+error.response.status+": "+error.response.statusText+")";
    }
    this.setState({error: err_msg});
  }

  clearError = () => {
    this.setState({error: ""});
  }

  componentDidMount() {    
    WebFont.load({
      google: {
        families: ['Monoton', 'Titillium Web', 'Nova Mono']
      }
    });
  }

  render() {
    return (
      <div className="App">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{blobs.app_title}</title>
          <meta name="description" content="Certified Curves Visualizer" />
          <link rel="canonical" href={blobs.canonical_ref} />
        </Helmet>
        <IsotopHeader
          motto={blobs.motto}
          info={this.infoText}
          banner={blobs.banner}
        />
        <IsotopCore
          onRequestStatusChange={this.handleRequestStatus}
          setStatus={this.handleStatus}
          setError={this.handleError}
          clearError={this.clearError}
          inProgress={this.state.request_in_progress}
        /> 
        <IsotopProgress
          inProgress={this.state.request_in_progress}
          status={this.state.status}
          statuses={this.state.statuses}
          count={this.operationsInProgress}
        />
        <IsotopFooter
          version={blobs.version}
          contact={blobs.contact}
          teamref={blobs.teamref}
          logos={[blobs.loria, blobs.inria, blobs.ul, blobs.cnrs]}
          credit={this.creditText}
          creditTitle={blobs.credit_title}
        />
        <IsotopError
          message={this.state.error}
        />
      </div>
    );
  }
}

export default App;
