import React from "react";

class IsotopBanner extends React.Component {
  render() {
    let images = this.props.logos.map((logo, index) => 
      <a key={index} className="App-footer-banner-element" href={logo.url}>
        <img key={index} alt={logo.alt} title={logo.alt} src={logo.image} />
      </a>
    );
    return(
      <div className="App-footer-banner">
        <div className="App-footer-banner-container">
          {images}
        </div>
      </div>
    );
  }
}

export default IsotopBanner;
