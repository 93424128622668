import React from "react";
import "./styles/App.css";
import TextField from 'material-ui/TextField';
import FlatButton from 'material-ui/FlatButton';


class IsotopSettingsBoundingBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {
        bbxmin: '', 
        bbxmax: '',
        bbymin: '',
        bbymax: ''
      }
    };
    this.handleChange = this.handleChange.bind(this);
  }

  // value to number ?
  handleChange(event, value) {
    const validity = this.isBoundaryValid(value);
    this.props.onValueChange(event.target.name, value, validity);
  }

  isBoundaryValid = (value) => (
    (value !== undefined) && (!Number.isNaN(Number.parseFloat(value)))
  )

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      console.log('Enter key pressed');
      event.preventDefault();
      this.props.handleClose(event);
    }
  }

  render() {
    return(
      <div className="App-content-settings-unit">
        <div className="App-content-settings-unit-title">
          Bounding-box
        </div>
        <div className="App-content-settings-unit-container">
          <div className="App-content-settings-unit-line">
            <TextField
              id="BBXMinInput"
              onKeyPress={this.handleKeyPress}
              onChange={this.handleChange}
              name="bbxmin"
              className="App-content-settings-unit-input"
              fullWidth={false}
              floatingLabelText="X min"
              floatingLabelFixed={true}
              hintText="Integer"
              errorText={this.props.errors.bbxmin}
              value={this.props.values.bbxmin === undefined ? '':this.props.values.bbxmin}/>
            <TextField
              id="BBXMaxInput"
              onKeyPress={this.handleKeyPress}
              onChange={this.handleChange}
              name="bbxmax"
              className="App-content-settings-unit-input"
              fullWidth={false}
              floatingLabelText="X max"
              floatingLabelFixed={true}
              hintText="Integer"
              errorText={this.props.errors.bbxmax}
              value={this.props.values.bbxmax === undefined ? '':this.props.values.bbxmax}/>
          </div>
          <div className="App-content-settings-unit-line">
            <TextField
              id="BBYMinInput"
              onKeyPress={this.handleKeyPress}
              onChange={this.handleChange}
              name="bbymin"
              className="App-content-settings-unit-input"
              fullWidth={false}
              floatingLabelText="Y min"
              floatingLabelFixed={true}
              hintText="Integer"
              errorText={this.props.errors.bbymin}
              value={this.props.values.bbymin === undefined ? '':this.props.values.bbymin}/>
            <TextField
              id="BBYMaxInput"
              onKeyPress={this.handleKeyPress}
              onChange={this.handleChange}
              name="bbymax"
              className="App-content-settings-unit-input"
              fullWidth={false}
              floatingLabelText="Y max"
              floatingLabelFixed={true}
              hintText="Integer"
              errorText={this.props.errors.bbymax}
              value={this.props.values.bbymax === undefined ? '':this.props.values.bbymax}/>
          </div>
          <FlatButton
            id="App-content-settings-reset-action"
            label="Reset"            
            disabled={!this.props.altered}
            onClick={this.props.onResetBoundingBox}/>
        </div>
      </div>
    );  
  }
}

export default IsotopSettingsBoundingBox;
