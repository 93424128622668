import React, { Component } from 'react';
import IsotopGraphId from './IsotopGraphId';

class IsotopGraph extends Component {
  render() {
    if ((!this.props.deleted) && (!this.props.refresh)) {
      return(
        <div className="App-content-result-graph">
          <div id="graph" className="App-content-result-graph-image">
          </div>
          <IsotopGraphId
            id={this.props.graph.isotop_id}
          />
        </div>
      );
    } else {
      return null;
    }
  }
}

export default IsotopGraph;
