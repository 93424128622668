import React, { Component } from 'react';
import {
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from 'material-ui/Table';
import Paper from 'material-ui/Paper';
import mui_styles from './styles/mui_styles';
import * as Helpers from "./helpers.js";

class IsotopSpecialPoints extends Component {
  genPointsTable = function(title, data) {
    let displayTitle = Helpers.capitalize(title);
    if (data == null) {
      return null;
    } else {
      return (
        <div className="App-content-result-specialpoints-data">
          <Paper style={mui_styles.paper} zDepth={1}>
            <table
              style={mui_styles.table}>
              <TableHeader
                displaySelectAll={false}
                adjustForCheckbox={false}
                style={mui_styles.th}>
                <TableRow 
                  style={(title === "singular" ? mui_styles.th_singular:mui_styles.th_extreme)}>
                  <TableHeaderColumn
                    colSpan="3"
                    tooltip={displayTitle+" points"}
                    style={(title === "singular" ? mui_styles.thcol_singular:mui_styles.thcol_extreme)}>
                    {displayTitle+"s"}
                  </TableHeaderColumn>
                </TableRow>
                <TableRow 
                  style={mui_styles.trh}>
                  <TableHeaderColumn 
                    tooltip="Point Index" 
                    style={mui_styles.thc}>
                    Index
                  </TableHeaderColumn>
                  <TableHeaderColumn 
                    tooltip="Abscissa" 
                    style={mui_styles.thc}>
                    X
                  </TableHeaderColumn>
                  <TableHeaderColumn 
                    tooltip="Ordinate" 
                    style={mui_styles.thc}>
                    Y
                  </TableHeaderColumn>
                </TableRow>
              </TableHeader>
              <TableBody
                displayRowCheckbox={false}
                showRowHover={true}
                stripedRows={true}
                style={mui_styles.tbody}>
                {data.map((row, index) => (
                  <TableRow 
                    key={index} 
                    style={mui_styles.tr}>
                    <TableRowColumn 
                      style={(title === "singular" ? mui_styles.trc_singular:mui_styles.trc_extreme)}>
                      {index}
                    </TableRowColumn>
                    <TableRowColumn 
                      style={(title === "singular" ? mui_styles.trc_singular:mui_styles.trc_extreme)}>
                      {row[0]}
                    </TableRowColumn>
                    <TableRowColumn 
                      style={(title === "singular" ? mui_styles.trc_singular:mui_styles.trc_extreme)}>
                      {row[1]}
                    </TableRowColumn>
                  </TableRow>
                ))}
              </TableBody>
            </table>
          </Paper>
        </div>
      );  
    }
  };

  render() {
    let singular_array = null;
    if ((this.props.singular) && (this.props.singular.length > 0)) {
      singular_array = this.genPointsTable(
        "singular",
        this.props.singular
      );
    }
    let extreme_array = null;
    if ((this.props.extreme) && (this.props.extreme.length > 0)) {
      extreme_array = this.genPointsTable(
        "extreme",
        this.props.extreme
      );
    }
    return (
      <div className="App-content-result-specialpoints">          
        {singular_array}
        {extreme_array}
      </div>
    );
  }
}

export default IsotopSpecialPoints;
