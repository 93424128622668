import React from 'react';
import { Dialog } from 'material-ui';
import IsotopBanner from "./IsotopBanner";
import preval from 'preval.macro';


class IsotopFooter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showCredits: false
    };
  }

  handleClick = (e) => {
    e.preventDefault();
    this.setState({showCredits: true});
  }

  handleClose = () => {
    this.setState({showCredits: false});
  }

  render() {
    const EPI_LINK='http://gamble.loria.fr/software.html';
    const SUPPORT_MAILTO='mailto:isotop-visu-support@inria.fr';

    return(
      <div className="App-footer">
        <IsotopBanner
          logos={this.props.logos}
        />
        <div className="App-footer-colophon">
          <div className="App-footer-colophon-left">
            {this.props.version}
            <br/>
            Last build: {preval`module.exports = new Date().toLocaleString();`}
          </div>
          <div className="App-footer-colophon-center">
            <a className="App-footer-link" href={SUPPORT_MAILTO}>
              {this.props.contact}
            </a>
          </div>
          <div className="App-footer-colophon-right">
            <a className="App-footer-link" href={EPI_LINK}>
              {this.props.teamref}
            </a>
            <a className="App-footer-link" href="#" onClick={this.handleClick}>
              Credits
            </a>
          </div>
        </div>
        <Dialog
          title={this.props.creditTitle}
          modal={false}
          open={this.state.showCredits}
          onRequestClose={this.handleClose}
          contentClassName="IsotopMoreExplanationsDialog">
          {this.props.credit}
        </Dialog>
      </div>
    );
  }
}

export default IsotopFooter;
