import React from 'react';
import './styles/App.css';
import mui_styles from './styles/mui_styles';
import RaisedButton from 'material-ui/RaisedButton';
import MenuItem from 'material-ui/MenuItem';
import {Toolbar, ToolbarGroup, ToolbarSeparator} from 'material-ui/Toolbar';
import IconButton from 'material-ui/IconButton';
import IconMenu from 'material-ui/IconMenu';
import Divider from 'material-ui/Divider';
import MoreVertIcon from 'material-ui/svg-icons/navigation/more-vert';
import ArrowDropRightIcon from 'material-ui/svg-icons/navigation-arrow-drop-right';
import DownloadIcon from 'material-ui/svg-icons/file/file-download';
import UploadIcon from 'material-ui/svg-icons/file/file-upload';
import SettingsIcon from 'material-ui/svg-icons/action/settings';
import TextField from 'material-ui/TextField';
import ActionRestore from 'material-ui/svg-icons/action/autorenew';
import ActionEdit from 'material-ui/svg-icons/editor/mode-edit';
import IsotopPolyChooser from './IsotopPolyChooser';
import InputFiles from 'react-input-files';
import labels from './res/blobs.json';
import styles from './styles/mui_styles';
import IsotopPolyEdit from './IsotopPolyEdit';
import IsotopPolyChecker from './IsotopPolyChecker';


class IsotopControls extends React.Component {
  constructor(props) {
    super(props);
    this.polyChecker = new IsotopPolyChecker();
    this.state = {
      polynomial_edit: false,
      errors: {
        polynomial: ''
      }
    };
    this.handleChoosePolynomial = this.handleChoosePolynomial.bind(this);
    this.handleChangePolynomial = this.handleChangePolynomial.bind(this);
    this.handleChangeFile = this.handleChangeFile.bind(this);
    this.handleClickGraph = this.handleClickGraph.bind(this);
    this.handleRestorePolynomial = this.handleRestorePolynomial.bind(this);
  }

  handleChoosePolynomial(poly) {
    this.props.onChangePolynomial(poly, this.checkPolynomialValidity(poly));
  }

  handleChangePolynomial(event) {
    const poly = event.target.value;
    this.props.onChangePolynomial(poly, this.checkPolynomialValidity(poly));
  }

  handleRestorePolynomial(event) {
    this.setState({errors: {
      ...this.state.errors, 
      polynomial: ''
    }});
    this.props.onClickRestore();
  }

  handleChangeFile(files) {
    if (this.props.inProgress === true) {
      return;
    }
    if (files[0] !== undefined) {
      const fpoly = files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const poly = event.target.result;
        this.props.onChangePolynomial(poly, this.checkPolynomialValidity(poly));
      }
      reader.readAsText(fpoly);
    } else return;
  }

  handleClickGraph(event) {
    event.preventDefault();
    if (this.props.polynomial.length > 0) {
      if ((this.props.graph_created === false) || 
        ((this.props.graph_created === true) && (this.props.polynomial_changed === true))) {
        this.props.onClickCreateGraph(event);      
      } else if ((this.props.graph_created === true) && (this.props.settings_changed === true)) {
        this.props.onClickUpdateGraph(event);
      }
    }
  }

  handleClickEdit = (event) => {
    this.setState({polynomial_edit: true});
  }

  handleCloseEdit = (event) => {
    const edit = this.state.polynomial_edit;
    this.setState({polynomial_edit: !edit});
  }

  checkPolynomialValidity = (polynomial) => {
    if (IsotopPolyChecker.isEmpty(polynomial)) {
      this.setState({errors: {
        ...this.state.errors, 
        polynomial: ''
      }});
      return false;
    } else if (this.polyChecker.validate(polynomial)) {
      this.setState({errors: {
        ...this.state.errors, 
        polynomial: ''
      }});
      return true;
    } else {
      this.setState({errors: {
        ...this.state.errors, 
        polynomial: labels.errors.polynomial
      }});
      return false;
    }
  }

  setPolynomialsChooserLabel = () => {
    let result = '';
    if (this.props.chooser === true) {
      result = 'hide polynomials';
    } else {
      result = 'choose polynomial';
    }
    return result;
  }

  setGraphTouchLabel = () => {
    let result = '';
    if ((this.props.graph_created === true) && 
        (this.props.polynomial_changed === false) && 
        (this.props.settings_changed === true)) {
      result = 'Re-graph';
    } else {
      result = 'Graph';
    }
    return result;
  }

  setSettingTouchLabel = () => {
    let result = '';
    if (this.props.settings === true) {
      result = 'Hide settings';
    } else {
      result = 'Show settings';
    }
    return result;
  }

  setSettingTouchDisabling = () => {
    return (this.props.inProgress === true);
  }

  setGraphTouchDisabling = () => {
    if (((this.props.graph_created === true) && 
      (this.props.polynomial_changed === false) && 
      (this.props.settings_changed === false) && 
      (this.props.graph_zoomed_translated === false)) ||  
      (this.props.polynomial.length === 0) || 
      (this.props.inProgress === true) ||
      (this.state.errors.polynomial.length > 0) ||
      (IsotopPolyChecker.isEmpty(this.props.polynomial))) {
      return true;
    } else {
      return false;
    }
  }

  // to modify
  setLoadTouchDisabling = () => {
    return (this.props.inProgress === true);
  }

  // to modify
  setChooseTouchDisabling = () => {
    return (this.props.inProgress === true);
  }

  setSettingsTouchDisabling = () => {
    if (this.props.polynomial.length === 0) {
      return true;
    } else {
      return (this.props.inProgress);
    }
  }

  setRestoreTouchActivation = () => {
    if ((this.props.graph_created === true) && (this.props.polynomial_changed === true)) {
      return false;
    } else {
      return true;
    }
  }

  render() {
    const enter_poly_label = 'Bivariate polynomial expression (with x, y variables) and integer coefficients in expanded form (Maple style) : x^2+3*y^2-1';
    let graph_touch_label = this.setGraphTouchLabel();
    let graph_touch_disabled = this.setGraphTouchDisabling();
    let restore_touch_disabled = this.setRestoreTouchActivation();
    let disabledEdit = ((this.props.polynomial.length === 0) || (this.props.inProgress === true));
    return(
      <Toolbar
        style={mui_styles.toolbar}
        className="App-content-controls-toolbar">
        <ToolbarGroup
          className='App-content-controls-toolbar-poly'>
          <ToolbarGroup
            className='App-content-controls-toolbar-poly-choosing'>
            <InputFiles
              onChange={this.handleChangeFile}
              accept="text/plain"
              multiple={false}>
              <IconButton
                tooltip="Upload Polynomial"
                tooltipPosition="top-right"
                style={styles.uploadIcon}
                iconStyle={styles.smallIcon}
                disabled={this.props.inProgress}>
                <UploadIcon/>            
              </IconButton>
            </InputFiles>  
            <IsotopPolyChooser
              polynomial={this.props.polynomial}
              onChangePolynomial={this.handleChoosePolynomial}
              onClick={this.props.onClickChooser}>
            </IsotopPolyChooser>
          </ToolbarGroup>
          <ToolbarGroup
            className='App-content-controls-toolbar-poly-entry'>
            <form 
              className="App-content-controls-toolbar-poly-form" 
              onSubmit={this.handleClickGraph} action="">
              <TextField
                id="App-content-controls-toolbar-poly-input"
                onChange={this.handleChangePolynomial}
                floatingLabelText={enter_poly_label}
                multiLine={false}
                rows={1}
                rowsMax={1}
                fullWidth={true}
                errorText={this.state.errors.polynomial}
                value={this.props.polynomial}>
              </TextField>
            </form>
          </ToolbarGroup>
          <ToolbarGroup
            className='App-content-controls-toolbar-poly-actions'>
            <IconButton 
              tooltip="Restore polynomial"
              tooltipPosition="top-right"
              onClick={this.handleRestorePolynomial} 
              disabled={restore_touch_disabled}
              style={mui_styles.mediumIcon}>
              <ActionRestore/>
            </IconButton>
            <IconButton 
              tooltip="Edit polynomial"
              tooltipPosition="top-right"
              onClick={this.handleClickEdit}
              disabled={disabledEdit} 
              style={mui_styles.mediumIcon}>
              <ActionEdit/>
            </IconButton>
          </ToolbarGroup>
        </ToolbarGroup>
        <ToolbarGroup 
          className='App-content-controls-toolbar-actions'>
          <ToolbarSeparator/>
          <RaisedButton
            id="App-controls-graph-action"
            onClick={this.handleClickGraph}
            disabled={graph_touch_disabled}
            style={mui_styles.button_graph}
            label={graph_touch_label}
            primary={true} 
            fullWidth={false}>
          </RaisedButton>
          <IconMenu
            name="App-content-controls-more-button"
            iconButtonElement={<IconButton><MoreVertIcon /></IconButton>}
            anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
            targetOrigin={{horizontal: 'right', vertical: 'top'}}
            clickCloseDelay={200}>
            <MenuItem 
              name="App-content-controls-settings-button"
              leftIcon={<SettingsIcon />} 
              primaryText="Settings"
              onClick={this.props.onClickSettings}
            />
            <Divider />
            <MenuItem
              primaryText="Download"
              leftIcon={<DownloadIcon />} 
              rightIcon={<ArrowDropRightIcon />}
              disabled={true}
              menuItems={[
                <MenuItem primaryText="Graph Data" />
              ]}
            />
          </IconMenu>
        </ToolbarGroup>
        <IsotopPolyEdit
          open={this.state.polynomial_edit}
          polynomial={this.props.polynomial}
          handleClose={this.handleCloseEdit}
          onChangePolynomial={this.handleChangePolynomial}
          error={this.state.errors.polynomial}
          handleClickGraph={this.handleClickGraph}
        />
      </Toolbar>
    );
  }
}

export default IsotopControls;

// <InputFiles
//               onChange={this.handleChangeFile}
//               accept="text/plain"
//               multiple={false}>
//                 <MenuItem
//                   primaryText="Upload Polynomial"
//                   leftIcon={<UploadIcon/>}/>
//             </InputFiles> 
