import React from 'react';
import './styles/App.css';
import Textfield from 'material-ui/TextField';
import FlatButton from 'material-ui/FlatButton';
import Dialog from 'material-ui/Dialog/Dialog';

class IsotopPolyEdit extends React.Component {
    handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        this.props.handleClose();
        this.props.handleClickGraph(event);
      }
    }

    render() {
      return(
        <Dialog
          title="Edit Polynomial Expression"
          modal={false}
          open={this.props.open}
          actions={[
            <FlatButton
              label='Done'
              primary={true}
              keyboardFocused={false}
              onClick={this.props.handleClose}
            />]}>
          <Textfield
            id="App-content-controls-poly-edit"
            multiLine={true}
            fullWidth={true}
            rows={1}
            rowsMax={20}
            onChange={this.props.onChangePolynomial}
            value={this.props.polynomial}
            onKeyPress={this.handleKeyPress}
            errorText={this.props.error}/>
        </Dialog>
      );
    }
}

export default IsotopPolyEdit;
