const styles = {
  uploadInput: {
    cursor: 'pointer',
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    width: '100%',
    opacity: 0, 
    padding: '12px'
  },
  button: {
    margin: '5px'
  },
  button_graph: {
    margin: '5px auto',
    minWidth: '60%'
  },
  paper: {
    height: 'auto',
    width: '100%',
    padding: '0px 2px 10px 2px',
    // textAlign: 'center',
    display: 'inline-block',
    borderRadius: '10px',
  },
  label: {
    width: '100%',
  }, 
  input: {
    float: 'right'
  }, 
  progress: {
    height: 400,
    width: 400
  },
  table: {
    height: 'auto',
    width: 'auto%',
    padding: '0', 
    margin: '0'
  },
  th: {
    fontSize: 'medium',
    textAlign: 'center', 
    height: 'auto',
    width: 'auto',
    padding: '0', 
    margin: '0'
  },
  thcol_extreme: {
    color: 'red',
    fontSize: 'medium',
    fontWeight: 'bold',
    textAlign: 'center', 
    height: 'auto', 
    width: '100%',
    padding: '5px 10px'
  },
  thcol_singular: {
    color: 'green',
    fontSize: 'medium',
    fontWeight: 'bold',
    textAlign: 'center', 
    height: 'auto', 
    width: '100%',
    padding: '5px 10px'
  },
  th_extreme: {
    textAlign: 'center', 
    height: 'auto',
    width: '100%',
    padding: '0', 
    margin: '0'
  },
  th_singular: {
    textAlign: 'center', 
    height: 'auto',
    width: '100%',
    padding: '0', 
    margin: '0'
  },
  trh: {
    fontSize: 'small',
    textAlign: 'center', 
    height: 'auto',
    width: 'auto',
    margin: '0'
  },
  thc: {
    height: 'auto',
    fontSize: 'small',
    fontWeight: 'bold',
    textAlign: 'center', 
    margin: '0', 
    padding: '3px 10px'
  },
  thc_extreme: {
    color: 'red', 
    fontSize: 'medium',
    textAlign: 'center', 
    height: 'auto',
    width: 'auto',
    padding: '0', 
    margin: '0'
  },
  thc_singular: {
    color: 'green', 
    fontSize: 'medium',
    textAlign: 'center', 
    height: 'auto',
    width: 'auto',
    padding: '0', 
    margin: '0'
  },
  tr: {
    height: 'auto',
    width: 'auto',
    padding: '0', 
    margin: '0'
  },
  trc_singular: {
    fontSize: 'small',
    textAlign: 'center', 
    height: 'auto',
    margin: '0', 
    padding: '3px 10px',
    color: 'green'
  },
  trc_extreme: {
    fontSize: 'small',
    textAlign: 'center', 
    height: 'auto',
    margin: '0',
    padding: '3px 10px',
    color: 'red'

  },
  tbody: {
    width: 'auto', 
    margin: '0'

  },
  toggleLabel: {
    width: 'auto', 
    float: 'right'
  },
  slider: {
    margin: '10px 0 5px 0',
    padding: '10px 20px',
  }, 
  icon_motto: {
    display: 'flex',
    margin: 'auto 5px 12px 5px',
    padding: '0',
    width: 'auto',
    height: 'auto'
  }, 
  toolbar: {
    height: 'auto',
    backgroundColor: '#FFFFFF'
  }, 
  radioButton: {
    marginBottom: 16,
  },
  settings: {
    overflowY: 'auto'
  }, 
  smallIcon: {
    width: 36,
    height: 36,
  },
  mediumIcon: {
    width: 48,
    height: 48,
  },
  largeIcon: {
    width: 60,
    height: 60,
  },
  uploadIcon: {
  }
};

export default styles;
