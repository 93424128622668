import css_colors from './styles/css_colors';

function MPConvert(mpString) {
  let result = Number.parseFloat(mpString);
  return result;
}

const dereferenceSegmentsToFlatCoordinates = function(segments, points) {
  let result = [];
  segments.forEach(function(seg) {
    let segment_dereferenced = [];
    let vertexleft = dereferencePointsToVertex(points, seg.endpoints.left);
    let vertexright = dereferencePointsToVertex(points, seg.endpoints.right);
    segment_dereferenced.push([
      MPConvert(vertexleft.mp_x),
      MPConvert(vertexleft.mp_y),
    ]);
    segment_dereferenced.push([
      MPConvert(vertexright.mp_x),
      MPConvert(vertexright.mp_y),
    ]);
    result.push(segment_dereferenced);
  });
  return result;
};
export { dereferenceSegmentsToFlatCoordinates };

const translateSegmentsArrayToPolylineDataList = function(segments, options) {
  let result = { data: [] };
  segments.forEach(function(seg) {
    let elem = {
      points: seg
    };
    Object.assign(elem, options);
    result.data.push(elem);
  });
  return result;
};
export { translateSegmentsArrayToPolylineDataList };

const dereferenceSegmentsToPolylineDataList = function(segments, points, options) {
  return translateSegmentsArrayToPolylineDataList(
    dereferenceSegmentsToFlatCoordinates(segments, points), options
  );
};
export { dereferenceSegmentsToPolylineDataList };

const dereferencePointsToVertex = function(points, index) {
  let result = {};
  for (let i = 0; i < points.length; i++) {
    if (points[i].index === index) {
      result = points[i];
      break;
    }
  }
  return result.vertex;
};
export { dereferencePointsToVertex };

const dereferenceBoundingBoxXAxis = function(boundaries) {
  let bmin = MPConvert(boundaries.mp_xmin);
  let bmax = MPConvert(boundaries.mp_xmax);
  let min = bmin < bmax ? bmin : bmax;
  let max = bmin < bmax ? bmax : bmin;
  let result = [min, max];
  return result;
};
export { dereferenceBoundingBoxXAxis };

const dereferenceBoundingBoxYAxis = function(boundaries) {
  let bmin = MPConvert(boundaries.mp_ymin);
  let bmax = MPConvert(boundaries.mp_ymax);
  let min = bmin < bmax ? bmin : bmax;
  let max = bmin < bmax ? bmax : bmin;
  let result = [min, max];
  return result;
};
export { dereferenceBoundingBoxYAxis };

const sievePointsByType = function(points, type) {
  let result = [];
  points.forEach(function(point) {
    if (point.vertex[type] === 'true') {
      result.push([point.vertex.mp_x, point.vertex.mp_y]);
    }
  });
  if (result.length > 0) {
    return result;
  } else {
    return null;
  }
};
export { sievePointsByType };

const dereferenceSpecialPoints = function(points, options, type) {
  let extpoints = sievePointsByType(points, type);
  let result = {
    points: extpoints
  };
  Object.assign(result, options);
  return result;
};
export { dereferenceSpecialPoints };

const convertPointsToCrosses = function(points, options, crossXWidth, crossYWidth) {
  let result = [];
  points.forEach(function(point) {
    let apoint = {points: []};
    Object.assign(apoint, options);
    apoint.points.push([MPConvert(point[0])-crossXWidth, MPConvert(point[1])-crossYWidth], 
      [MPConvert(point[0]), MPConvert(point[1])]);
    result.push(apoint);
    apoint = {points: []};
    Object.assign(apoint, options);
    apoint.points.push([MPConvert(point[0]), MPConvert(point[1])], 
      [MPConvert(point[0])+crossXWidth, MPConvert(point[1])+crossYWidth]);
    result.push(apoint);
    apoint = {points: []};
    Object.assign(apoint, options);
    apoint.points.push([MPConvert(point[0])-crossXWidth, MPConvert(point[1])+crossYWidth], 
      [MPConvert(point[0]), MPConvert(point[1])]);
    result.push(apoint);
    apoint = {points: []};
    Object.assign(apoint, options);
    apoint.points.push([MPConvert(point[0]), MPConvert(point[1])], 
      [MPConvert(point[0])+crossXWidth, MPConvert(point[1])-crossYWidth]);
    result.push(apoint);
  });
  if (result.length > 0) {
    return result;
  } else {
    return null;
  }
};
export { convertPointsToCrosses };

const computeYScale = function(width, height, xScale) {
  const xDiff = xScale[1] - xScale[0];
  const yDiff = height * xDiff / width;
  return [-yDiff / 2, yDiff / 2];
};
export { computeYScale };

const GRAPH_RATIO_SPACE = 0.5;

const getGraphWidth = function() {
  return Math.trunc(window.innerWidth * GRAPH_RATIO_SPACE);
};
export { getGraphWidth };

const getGraphHeight = function() {
  return Math.trunc(window.innerHeight * GRAPH_RATIO_SPACE);
};
export { getGraphHeight };

const getNewColor = function() {
  return css_colors.CSS_COLOR_NAMES[
    Math.floor(Math.random()*css_colors.CSS_COLOR_NAMES.length)
  ];
};
export { getNewColor };

const capitalize = function(myString) {
  return myString.charAt(0).toUpperCase()+myString.slice(1);
};
export { capitalize };

