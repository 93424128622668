import React from "react";
import "./styles/App.css";
import IsotopSettingsPrecision from './IsotopSettingsPrecision';
import IsotopSettingsBoundingBox from './IsotopSettingsBoundingBox';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import mui_styles from './styles/mui_styles';
import blobs from './res/blobs.json';

class IsotopSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state =  {
      errors: {
        bbxmin: '',
        bbxmax: '',
        bbymin: '',
        bbymax: ''
      },
    };
    this.setError = this.setError.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  setError(name, isValid) {
    if (isValid === true) {
      this.setState({
        errors: {
          ...this.state.errors, 
          [name]: ''
        }
      });
    } else {
      this.setState({
        errors: {
          ...this.state.errors, 
          [name]: blobs.errors.nan
        }
      });
    }
  }

  isInError = () => {
    return (this.state.errors.bbxmin.length !== 0 ||
      this.state.errors.bbxmax.length !== 0 ||
      this.state.errors.bbymin.length !== 0 ||
      this.state.errors.bbymax.length !== 0   
    );
  }

  onValueChange = (name, value, validity) => {
    this.props.onValueChange(name, value);
    this.setError(name, validity);
  }

  handleClose(event) {
    if (this.isInError() === false) {
      this.props.onHandleClose();
    }
  }


  render() {
    return (
      <Dialog
        modal={true}
        width='auto'
        open={this.props.open}
        bodyStyle={mui_styles.settings}
        actions={[
          <FlatButton
            label='Apply'
            primary={true}
            keyboardFocused={false}
            onClick={this.handleClose}
          />
        ]}
        contentClassName="App-content-settings">
        <div className="App-content-settings-title">
          Settings
        </div>
        <div className="App-content-settings-set">
          <IsotopSettingsPrecision
            onValueChange={this.props.onValueChange}
            precision_select={this.props.values.precision_select}
            precision={this.props.values.precision}
            handleClose={this.handleClose}>
          </IsotopSettingsPrecision>
          <IsotopSettingsBoundingBox
            onValueChange={this.onValueChange}
            values={this.props.values}
            errors={this.state.errors}
            altered={this.props.BoundingBoxAltered}
            onResetBoundingBox={this.props.onResetBoundingBox}
            setError={this.setError}
            handleClose={this.handleClose}>
          </IsotopSettingsBoundingBox>
        </div>
      </Dialog>
    );
  }
}

export default IsotopSettings;
