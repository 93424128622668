import React from 'react';


class IsotopError extends React.Component {
  render() {
    if (this.props.message !== '') {
      return(
        <div className="App-error">{this.props.message}</div>
      );
    } else {
      return null;
    }
  }
}

export default IsotopError;
