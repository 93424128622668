import React from "react";
import "./styles/App.css";
import RadioButton from 'material-ui/RadioButton';
import RadioButtonGroup from 'material-ui/RadioButton/RadioButtonGroup';
import Slider from 'material-ui/Slider';
import mui_styles from './styles/mui_styles';

class IsotopSettingsPrecision extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event, value) {
    let label = event.target.name;
    if (label === undefined) {
      label = 'precision';
    }
    this.props.onValueChange(label, value);
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.props.handleClose(event);
    }
  }

  render() {
    let slider_disabled = (this.props.precision_select === 'auto');
    return(
      <div className="App-content-settings-unit">
        <div className="App-content-settings-unit-title">
          Precision
        </div>
        <div className="App-content-settings-unit-content">
          <RadioButtonGroup 
            name="precision_select"
            defaultSelected="auto"
            labelPosition="left"
            valueSelected={this.props.precision_select}
            onChange={this.handleChange}>
            <RadioButton
              onKeyPress={this.handleKeyPress}
              value="auto"
              label="Automatically selected"
              style={mui_styles.radioButton}
            />
            <RadioButton
              onKeyPress={this.handleKeyPress}
              value="user"
              label="Selected by user"
              style={mui_styles.radioButton}
            />
          </RadioButtonGroup>
        </div>
        <div className="App-content-settings-unit-content">
          <Slider
            className="sortable-handler"
            name="precision"
            disabled={slider_disabled}
            sliderStyle={mui_styles.slider}
            min={1}
            max={100}
            step={1}
            // value={this.props.precision}
            defaultValue={this.props.precision}
            onKeyPress={this.handleKeyPress}
            onChange={this.handleChange}/>
            <div className="App-content-settings-precision-labels">
              <div className="App-content-settings-precision-labels-min">
                Low
              </div>
              <div className="App-content-settings-precision-labels-current">
                {this.props.precision}
              </div>
              <div className="App-content-settings-precision-labels-max">
                High
              </div>
            </div>
          </div>
        </div>
    );
  }
}

export default IsotopSettingsPrecision;
