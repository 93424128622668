import React from "react";
import "./styles/App.css";
import DropDownMenu from 'material-ui/DropDownMenu';
import MenuItem from 'material-ui/MenuItem';
import examples from './res/polynomials-examples.json';


class IsotopPolyChooser extends React.Component {
  componentWillMount() {
    this.setState({
      selectedIndex: this.props.defaultValue,
    });
  }

    handleChange = (event, index) => {
      this.setState({
        selectedIndex: index,
      });
      this.props.onChangePolynomial(examples[index].equation);
    };

    getIndex() {
      if (!Number.isNaN(this.state.selectedIndex) && this.state.selectedIndex >= 0) {
        if (this.props.polynomial === examples[this.state.selectedIndex].equation) {
          return this.state.selectedIndex;
        } else return 0;    
      } else return 0;
    }

    render() {
      let index = this.getIndex();
      return(
        <DropDownMenu
          id="App-content-polychoose-action"
          value={index}
          onChange={this.handleChange}>
          {
            examples.map((poly, index) => {
              return [
                <MenuItem
                  value={index}
                  key={index}
                  primaryText={poly.name}/>
              ]
            })
          }
        </DropDownMenu>
      );
    }
}

export default IsotopPolyChooser;
