import React from 'react';
import ReactDOM from 'react-dom';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import App from './App';

console.log(`Runtime Environment => [ ${process.env.NODE_ENV} ]`);

ReactDOM.render( 
  <MuiThemeProvider>
    <App/> 
  </MuiThemeProvider>,
  document.getElementById('root')
);
