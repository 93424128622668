import React from 'react';
import functionPlot from 'function-plot';
import SyncRequest from 'sync-request';

import IsotopControls from './IsotopControls';
import IsotopGraph from './IsotopGraph';
import IsotopSpecialPoints from './IsotopSpecialPoints';
import IsotopSettings from './IsotopSettings';
// import { APIRequest, APIBaseURL,  APIHeaders } from './APIRequest';
import { APIRequest, API_ROOT } from './APIRequest';
import * as Helpers from './helpers';

import './styles/App.css';



class IsotopCore extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      graph: {},
      refresh_graph: false,
      polynomial_display: "",
      polynomial_is_valid: false,
      precision_server: undefined,
      values: {
        precision: 3,
        precision_select: 'auto',
        bbxmin: undefined,
        bbxmax: undefined,
        bbymin: undefined,
        bbymax: undefined
      },
      values_graphed: {
        precision: 3,
        bbxmin: undefined,
        bbxmax: undefined,
        bbymin: undefined,
        bbymax: undefined
      },
      values_created: {
        precision: 3,
        bbxmin: undefined,
        bbxmax: undefined,
        bbymin: undefined,
        bbymax: undefined
      },
      curve: {
        width: 0,
        height: 0,
        color: undefined
      },
      settings_changed: false,
      polynomial_changed: false,
      precision_changed: false,
      boundingbox_changed: false,
      boundingbox_altered: false,
      graph_deleted: true,
      graph_created: false,
      graph_zoomed_translated: false,
      singular_points: [],
      extreme_points: [], 
      chooser: false,
      settings: false, 
      precision_toggled: false,
      precision_scale: ['undefined', 10, 20, 50, 100]
    };
    this.polynomial = "";
    this.polynomial_graphed = "";
    this.graph_image_width = 0;
    this.graph_image_height = 0;
    this.pixels_per_um_x = 0;
    this.pixels_per_um_y = 0;
    this.translation_vector = [0, 0];
    this.zoom_factor = 1.0;
    this.MAX_POLYNOMIAL_DISPLAY_SIZE = 150;
    this.BBOX_RATIO_CROSS = 0.01;
    this.GRAPH_MIN_WIDTH = 400;
    this.GRAPH_WINDOW_RATIO = 0.35;
    this.GRAPH_ASPECT_RATIO = 4 / 3;
  }

  onUnload = (event) => {
    event.preventDefault();
    console.log('*** onUnload CATCHED ***');
    this.deleteGraphSync();
  }

  componentDidMount() {
    console.log('*** React Hook : componentDidMount ***');
    window.addEventListener('beforeunload', this.onUnload);
  }

  componentWillUnmount() {
    console.log('*** React Hook : componentWillUnmount ***');
    window.removeEventListener('beforeunload', this.onUnload);
  }

  drawGraph = (data) => {
    this.setState({graph: data});
    let dataforgraph = { data: [] };
    let polyLineList = Helpers.dereferenceSegmentsToPolylineDataList(
      this.state.graph.segments,
      this.state.graph.points,
      {
        fnType: "points",
        graphType: "polyline",
        skipBoundsCheck: true,
        color: "steelblue"
      }
    );
    if (polyLineList != null) {
      dataforgraph.data = polyLineList.data;
    }
    let xScale = Helpers.dereferenceBoundingBoxXAxis(data.bbox.boundaries);
    let yScale = Helpers.dereferenceBoundingBoxYAxis(data.bbox.boundaries);
    let newValues = {
      ...this.state.values,
      bbxmin: xScale[0],
      bbxmax: xScale[1],
      bbymin: yScale[0],
      bbymax: yScale[1] 
    };
    let graph_width = window.innerWidth * this.GRAPH_WINDOW_RATIO;
    graph_width = (graph_width < this.GRAPH_MIN_WIDTH ? this.GRAPH_MIN_WIDTH : graph_width);
    // Compute Graph height window to respect aspect ratio
    // let graph_height = ((yScale[1] - yScale[0]) / (xScale[1] - xScale[0])) * this.GRAPH_WIDTH;
    let graph_height = graph_width / this.GRAPH_ASPECT_RATIO;
    let singpoints = Helpers.dereferenceSpecialPoints(data.points, {
      fnType: "points",
      graphType: "scatter",
      color: "#00FF00"
    }, 'singular');
    if (singpoints.points) {
      dataforgraph.data.push(singpoints);
      this.setState({ singular_points: singpoints.points });
    } else {
      this.setState({singular_points: []});
    }
    let extpoints = Helpers.dereferenceSpecialPoints(data.points, {
      fnType: "points",
      graphType: "scatter",
      color: "#FF0000"
    }, 'extreme');
    if (extpoints.points) {
      dataforgraph.data.push(extpoints);
      this.setState({ extreme_points: extpoints.points });
    } else {
      this.setState({extreme_points: []});
    }

    // this.crossXWidth = this.BBOX_RATIO_CROSS * (xScale[1] - xScale[0]);
    // this.crossYWidth = this.BBOX_RATIO_CROSS * (yScale[1] - yScale[0]);
    // let singpoints = Helpers.sievePointsByType(data.points, 'singular');
    // if (singpoints != null) {
    //   this.setState({ singular_points: singpoints });
    //   let cross_singpoints = Helpers.convertPointsToCrosses(singpoints, {
    //     fnType: "points",
    //     graphType: "polyline",
    //     color: "green"
    //   }, this.crossXWidth, this.crossYWidth);
    //   cross_singpoints.forEach(function(cross) {
    //     dataforgraph.data.push(cross);
    //   });
    // }
    // let extpoints = Helpers.sievePointsByType(data.points, 'extreme');
    // if (extpoints != null) {
    //   this.setState({ extreme_points: extpoints });
    //   let cross_extpoints = Helpers.convertPointsToCrosses(extpoints, {
    //     fnType: "points",
    //     graphType: "polyline",
    //     color: "red"
    //   }, this.crossXWidth, this.crossYWidth);
    //   cross_extpoints.forEach(function(cross) {
    //     dataforgraph.data.push(cross);
    //   });      
    // }
    this.setState({ 
      ...this.state, 
      values: newValues, 
      precision_server: data.bbox.x_sampling, 
      graph_zoomed_translated: false
    });
    let options = {
      width: graph_width, 
      height: graph_height,
      target: "#graph",
      grid: true,
      disableZoom: false,
      xAxis: {
        label: "x - axis",
        domain: xScale
      },
      yAxis: {
        label: "y - axis",
        domain: yScale
      },
      data: dataforgraph.data
      // plugins: [functionPlot.plugins.zoomBox()]
    };
    let instance = functionPlot(options);
    this.graph_image_width = instance.meta.width;
    this.graph_image_height = instance.meta.height;
    let self = this;
    instance.on('all:zoom', function(event) {
      self.handleMovement([event.transform.x, event.transform.y], event.transform.k, self);
    });
  }

  handleMovement = (translation, zoom, self) => {
    self.setState({graph_zoomed_translated: true});
    self.translation_vector = translation;
    self.zoom_factor = zoom;
    self.pixels_per_um_x = (self.graph_image_width / (self.state.values_graphed.bbxmax - self.state.values_graphed.bbxmin)) * self.zoom_factor;
    self.pixels_per_um_y = (self.graph_image_height / (self.state.values_graphed.bbymax - self.state.values_graphed.bbymin)) * self.zoom_factor;
    self.actualizeBoundingBox();
  }

  actualizeBoundingBox = () => {
    let new_bbxmin = this.state.values_graphed.bbxmin - (this.translation_vector[0] / this.pixels_per_um_x);
    let new_bbxmax = new_bbxmin + (this.graph_image_width / this.pixels_per_um_x);
    let new_bbymax = this.state.values_graphed.bbymax + (this.translation_vector[1] / this.pixels_per_um_y);
    let new_bbymin = new_bbymax - (this.graph_image_height / this.pixels_per_um_y);
    this.setState({values: {
      ...this.state.values,
      bbxmin: new_bbxmin,
      bbxmax: new_bbxmax,
      bbymin: new_bbymin,
      bbymax: new_bbymax
    }, 
    settings_changed: true, 
    boundingbox_altered: true,
    boundingbox_changed: true});
  }

  handleTranslation = (x, y, self) => {
    console.log('x = '+x.toString()+' y = '+y.toString());
  }
  
  handleGraphCreated = (response) => {
    this.setState({
      curve: {
        ...this.state.curve, 
        height: Helpers.getGraphHeight(), 
        width: Helpers.getGraphWidth()
      },
      graph_deleted: false,
      graph_created: true
    });
    this.drawGraph(response.data);
    this.polynomial_graphed = this.polynomial;
    this.setState({
      values_created: {...this.state.values},
      values_graphed: {...this.state.values}, 
      polynomial_changed: false,
      settings_changed: false,
      boundingbox_altered: false
    });
  }

  handleGraphUpdated = (response) => {
    this.setState({refresh_graph: false});
    this.drawGraph(response.data);
    this.setState({
      values_graphed: {...this.state.values}, 
      settings_changed: false
    });
  };

  handleGraphDeleted = () => {
    this.setState({
      graph_deleted: true,
      graph_created: false,
      polynomial_changed: false,
      settings_changed: false
    });
  };

  handleRefreshCallback = () => {
    this.setState({refresh_graph: false});
  }

  getPrecision = () => {
    return(this.state.values.precision_select === 'auto'? undefined:this.state.values.precision)
  }

  waitForGraph = (id, handleCallback) => {
    const self = this;
    APIRequest.get(`${API_ROOT}/${id}/status`)
      .then((response) => {
        if (response.status === 200) {
          // graph data is available
          APIRequest.get(`${API_ROOT}/${id}`)
            .then((response) => {
              // self.handleGraphCreated(response);
              self.props.onRequestStatusChange(false, 'GET');
              handleCallback(response);
            })
            .catch((error) => {
              self.props.onRequestStatusChange(false, 'GET');
              self.props.setError(error);
            })
        } else {
          // graph not yet ready
          // self.props.onRequestStatusChange(true, 'POST', `Get status for graph #${id}...`);
          // self.props.onRequestStatusChange(false, 'GET');
          setTimeout(self.waitForGraph, 200, id, handleCallback);
        }
      })
      .catch((error) => {
        self.props.onRequestStatusChange(false, 'GET');
        self.props.setError(error);
      });

  }

  createGraph = () => {
    this.props.clearError();
    if (this.state.polynomial_is_valid === true) {
      this.props.onRequestStatusChange(true, 'POST', 'Request new graph');
      let self = this;
      APIRequest.post(`${API_ROOT}`, {
        data: {
          polynomial: self.polynomial,
          bbox: {
            boundaries: {
              mp_xmin: String(self.state.values.bbxmin),
              mp_xmax: String(self.state.values.bbxmax),
              mp_ymin: String(self.state.values.bbymin),
              mp_ymax: String(self.state.values.bbymax)
            },
            x_sampling: String(this.getPrecision()),
            precision: String(self.state.values.precision)
          }
        },
        onDownloadProgress: function(progressEvent) {}
      })
        .then((response) => {
          self.props.onRequestStatusChange(false, 'POST');
          const id = response.data.isotop_id;
          self.props.onRequestStatusChange(true, 'GET', `Graph #${id} creation in progress...`);
          self.waitForGraph(id, self.handleGraphCreated);
        })
        .catch((error) => {
          self.props.onRequestStatusChange(false, 'POST');
          self.props.setError(error);
        });
    }
  };

  updateGraph = () => {
    this.props.clearError();
    if (this.state.polynomial_is_valid === true) {
      this.props.onRequestStatusChange(true, 'PUT', 'Request update for graph #' + this.state.graph.isotop_id);
      let self = this;
      const id = self.state.graph.isotop_id;
      APIRequest.put(`${API_ROOT}/${id}`, {
        data: {
          bbox: {
            boundaries: {
              mp_xmin: String(self.state.values.bbxmin),
              mp_xmax: String(self.state.values.bbxmax),
              mp_ymin: String(self.state.values.bbymin),
              mp_ymax: String(self.state.values.bbymax)
            },
            x_sampling: String(this.getPrecision()),
            precision: String(self.state.values.precision)
          }
        }
      })
        .then(function() {
          self.props.onRequestStatusChange(false, 'PUT');
          self.props.onRequestStatusChange(true, 'GET', `Graph #${id} update in progress...`);
          self.waitForGraph(id, self.handleGraphUpdated)
        // self.handleGraphUpdated(response);
        })
        .catch(function(error) {
          self.props.onRequestStatusChange(false, 'PUT');
          self.props.setError(error);
        });
    }
  }

  deleteGraphSync = async () => {
    this.props.clearError();
    if ((this.state.graph_created === true) && 
        (Number.isInteger(this.state.graph.isotop_id))) {
      try {
        await APIRequest.delete(`${API_ROOT}/${this.state.graph.isotop_id}`);
        // SyncRequest('DELETE', `${APIBaseURL}/${this.state.graph.isotop_id}`, { 
        //   headers: APIHeaders
        // });
        // let response = JSON.parse(result.getBody('utf8'));
      } catch(error) {
        console.log(error.toString());
        this.props.setError(error);
      }      
    }
  }

  deleteGraph = () => {
    let self = this;
    self.props.clearError();
    if (this.state.polynomial_is_valid === true) {
      self.props.onRequestStatusChange(true, 'DELETE', 'Freeing resources for graph#' + self.state.graph.isotop_id);
      APIRequest.delete(`${API_ROOT}/${self.state.graph.isotop_id}`)
        .then(function(response) {
          self.props.onRequestStatusChange(false, 'DELETE');
          self.handleGraphDeleted();
        })
        .catch(function(error) {
          self.props.onRequestStatusChange(false, 'DELETE');
          self.props.setError(error);
        });
    }
  }

  handleClickCreateGraph = event => {
    this.setState({singular_points: []});
    this.setState({extreme_points: []});
    if (this.state.graph_created === true && this.state.polynomial_changed === true) {
      this.deleteGraph();
      this.createGraph();
    } else if (this.state.graph_created === false) {
      this.createGraph();
    }
  };

  handleClickUpdateGraph = event => {
    this.setState({refresh_graph: true});
    this.setState({singular_points: []});
    this.setState({extreme_points: []});
    this.updateGraph();
  };

  handleTouchTapChooser = event => {
    this.setState({chooser: !this.state.chooser});
  }

  handleClickSettings = event => {
    this.setState({
      ...this.state, 
      settings: !this.state.settings
    });
  }

  handClickClear = event => {
    this.polynomial = "";
    this.setState({polynomial_changed: true });
    this.setState({polynomial_display: ""});
    this.setState({settings: false});
  };

  handleClickRestore = event => {
    this.setState({
      polynomial_display: this.polynomial_graphed,
      polynomial_changed: false, 
      settings_changed: false
    });
  };

  handleTouchTapBoundingBoxClear = () => {
    this.setState({
      values: {
        ...this.state.values_graphed,
        bbxmin: undefined,
        bbymin: undefined,
        bbxmax: undefined,
        bbymax: undefined
      },
      errors: {
        ...this.state.errors,
        bbxmin: "", 
        bbxmax: "",
        bbymin: "",
        bbymax: ""
      },
      boundingbox_changed: false,
      settings_changed: true
    });
  };

  handleTouchBoundingBoxReset = () => {
    if (this.hasBoundingBoxBeenAltered()) {
      this.setState({settings_changed: true});
    }
    this.setState({
      values: {
        ...this.state.values,
        bbxmin: this.state.values_created.bbxmin,
        bbxmax: this.state.values_created.bbxmax,
        bbymin: this.state.values_created.bbymin,
        bbymax: this.state.values_created.bbymax
      },
      errors: {
        ...this.state.errors,
        bbxmin: "", 
        bbxmax: "",
        bbymin: "",
        bbymax: ""
      }, 
      boundingbox_altered: false
    });
  };

  handlePrecisionToggleTap = () => {
    let user_select = !this.state.precision_toggled;
    if (user_select === true) {
      this.setState({
        settings_changed: false,
        precision_toggled: true,
        values: {
          ...this.state.values,
          precision: this.state.values_graphed.precision
        }});
    } else {
      this.setState({
        settings_changed: true,
        precision_toggled:false,
        values: {
          ...this.state.values,
          precision: 3
        }});
    }
  }

  haveSettingsBeenChanged = () => {
    let result = false;
    for (let [field, value] of this.state.values.entries()) {
      result |= this.hasValueBeenChanged(field, value);
    }
    return result;
  };

  hasBoundingBoxBeenChanged = () => {
    let result = false;
    for (let field of Object.keys(this.state.values)) {
      if (field !== "precision") {
        result |= this.hasValueBeenChanged(field, this.state.values[field]);
      }
    }
    return result;
  }

  hasBoundingBoxBeenAltered = () => {
    let result = true;
    for (let field of Object.keys(this.state.values)) {
      if (field !== "precision") {
        result |= this.hasValueBeenAltered(field, this.state.values[field]);
      }
    }
    return result;
  }

  hasValueBeenChanged = (field, value) => {
    return (!(this.isEmpty(value)) &&
            !(Number.isNaN(value)) &&
            (String(value) !== String(this.state.values_graphed[field])));
  };

  hasValueBeenAltered = (field, value) => {
    return (!(this.isEmpty(value)) &&
            !(Number.isNaN(value)) &&
            (String(value) !== String(this.state.values_created[field])));
  }


  handleChangePolynomial = (polynomial, validity) => {
    this.polynomial = polynomial.trim();
    if (this.polynomial === this.polynomial_graphed) {
      this.setState({
        polynomial_changed: false });
    } else {
      this.setState({
        polynomial_changed: true, 
        polynomial_is_valid: validity, 
        boundingbox_altered: true, 
        settings_changed: true,
        values: {
          ...this.state.values,
          bbxmin: undefined,
          bbxmax: undefined,
          bbymin: undefined,
          bbymax: undefined
        } 
      });
    }
    this.setState({
      polynomial_display: this.polynomial, 
      chooser: false
    });
  };

  handleValueChange = (field, value) => {
    this.setState({
      values: {
        ...this.state.values,
        [field]: value
      }, 
      errors: {
        ...this.state.errors,
        [field]: ""
      }
    });
    if (this.hasValueBeenChanged(field, value)) {
      this.setState({settings_changed: true});   
    }
    if (field !== "precision") {
      if (this.hasValueBeenAltered(field, value)) {
        this.setState({boundingbox_altered: true});
      } else {
        this.setState({boundingbox_altered: false});
      }
    }
  };

  handleSettingsClose = () => {
    this.setState({
      settings: false
    });
  }

  isEmpty = (value) => (value == null || value.length === 0)

  getPolynomialDisplayValue = (polystr) => (
    (polystr && (polystr.length > this.MAX_POLYNOMIAL_DISPLAY_SIZE)) ?
      `${polystr.slice(0, this.MAX_POLYNOMIAL_DISPLAY_SIZE)} [...]`:polystr
  )

  render() {
    return (
      <div className="App-content">
        <IsotopControls
          polynomial={this.state.polynomial_display}
          graph_created={this.state.graph_created}
          graph_zoomed_translated={this.state.graph_zoomed_translated}
          polynomial_changed={this.state.polynomial_changed}
          boundingbox_changed={this.state.boundingbox_changed}
          settings_changed={this.state.settings_changed}
          inProgress={this.props.inProgress}
          settings={this.state.settings}
          chooser={this.state.chooser}
          onChangePolynomial={this.handleChangePolynomial}
          onClickCreateGraph={this.handleClickCreateGraph}
          onClickUpdateGraph={this.handleClickUpdateGraph}
          onClickClear={this.handClickClear}
          onClickRestore={this.handleClickRestore}
          onClickSettings={this.handleClickSettings}
          onClickChooser={this.handleTouchTapChooser}
        />
        <div className="App-content-result">
          <div id="App-content-result-container">
            <IsotopSpecialPoints
              singular={this.state.singular_points}
              extreme={this.state.extreme_points}
            />
            <IsotopGraph
              graph={this.state.graph}
              deleted={this.state.graph_deleted}
              refresh={this.state.refresh_graph}
            />
          </div>
        </div>
        <IsotopSettings
          open={this.state.settings}
          values={this.state.values}
          onHandleClose={this.handleSettingsClose}
          onValueChange={this.handleValueChange}
          BoundingBoxAltered={this.state.boundingbox_altered}
          BoundingBoxChanged={this.state.boundingbox_changed}
          onResetBoundingBox={this.handleTouchBoundingBoxReset}
        />
      </div>
    );
  }
}

export default IsotopCore;
