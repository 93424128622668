import React from "react";


class IsotopHeaderBanner extends React.Component {
  render() {
    return(<div className="App-header-banner">
      {this.props.text}
    </div>);
  }
}

export default IsotopHeaderBanner;
