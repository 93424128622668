import React, { Component } from 'react';


class IsotopGraphId extends Component {
  render() {
    if (this.props.id !== undefined) {
      return(
        <div className="App-content-result-graph-id">
          <div className="App-content-result-graph-id-value">
            Graph #{this.props.id}
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}
  
export default IsotopGraphId;
